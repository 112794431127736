import { FC } from 'react';
import { EmptyResultRow } from 'new-beginning/components/shared/Table/EmptyResultRow';
import { ListRow } from './TableRow/ListRow';
import { List } from '../lib/entities/list';
import { useNavigate } from 'react-router-dom';
import { useIntegrationConnectionDetails } from 'new-beginning/hooks/salesforce/useIntegrationConnectionDetails';

interface ListRowSetProps {
	isLoading: boolean;
	data?: Array<List>;
	isSalesforceVisible: boolean;
	handleDelete: (id: number) => void;
}

export const ListRowSet: FC<ListRowSetProps> = ({ isLoading, data, handleDelete, isSalesforceVisible }) => {
	const navigate = useNavigate();
	const { data: connectionDetails } = useIntegrationConnectionDetails();
	const isCrmConnected = connectionDetails?.connectionActive;

	if (isLoading) {
		return (
			<tbody>
				<EmptyResultRow colSpan={7} message='Loading...' />
			</tbody>
		);
	}

	if (!data || data.length === 0) {
		return (
			<tbody>
				<EmptyResultRow colSpan={7} message='No Lists Found' />
			</tbody>
		);
	}
	return (
		<tbody>
			{data.map((row) => (
				<ListRow
					key={row.id}
					listData={row}
					handleSync={() => navigate(`/Lists/Sync/${row.id}`)}
					handleDelete={() => handleDelete(row.id)}
					handleSelect={() => navigate(`/Lists/${row.id}`)}
					isSyncDisabled={!isSalesforceVisible || !isCrmConnected}
				/>
			))}
		</tbody>
	);
};
