import { RADIUS_SEARCH_DEFAULT } from 'app/constants';
import { BrokerContactsStore } from '../../components/pages/Search/BrokerContacts/BrokerContacts.types';

type LocationFilter = BrokerContactsStore['filters']['contactLocation'] | BrokerContactsStore['filters']['brokerOfficeLocations'];

export const createLocationMethods = <FilterType extends { filters: { [K in 'contactLocation' | 'brokerOfficeLocations']: LocationFilter } }>(
	filterName: 'contactLocation' | 'brokerOfficeLocations',
	set: (fn: (state: FilterType) => Partial<FilterType>) => void,
) => ({
	include: {
		add: ({ location, radiusMiles }: { location: LocationFilter['include'][number]['location']; radiusMiles?: number }) =>
			set(
				(state) =>
					({
						filters: {
							...state.filters,
							[filterName]: {
								...state.filters[filterName],
								include: [
									...state.filters[filterName].include,
									{
										location,
										radiusMiles: radiusMiles ?? RADIUS_SEARCH_DEFAULT,
									},
								],
							},
						},
					}) as Partial<FilterType>,
			),
		remove: ({ searchValue }: { searchValue: string }) =>
			set(
				(state) =>
					({
						filters: {
							...state.filters,
							[filterName]: {
								...state.filters[filterName],
								include: state.filters[filterName].include.filter((loc) => loc.location.searchValue !== searchValue),
							},
						},
					}) as Partial<FilterType>,
			),
	},
	exclude: {
		add: ({ location, radiusMiles }: { location: LocationFilter['exclude'][number]['location']; radiusMiles?: number }) =>
			set(
				(state) =>
					({
						filters: {
							...state.filters,
							[filterName]: {
								...state.filters[filterName],
								exclude: [
									...state.filters[filterName].exclude,
									{
										location,
										radiusMiles: radiusMiles ?? RADIUS_SEARCH_DEFAULT,
									},
								],
							},
						},
					}) as Partial<FilterType>,
			),
		remove: ({ searchValue }: { searchValue: string }) =>
			set(
				(state) =>
					({
						filters: {
							...state.filters,
							[filterName]: {
								...state.filters[filterName],
								exclude: state.filters[filterName].exclude.filter((loc) => loc.location.searchValue !== searchValue),
							},
						},
					}) as Partial<FilterType>,
			),
	},
});
