export enum QueryPath {
	List = 'lists/getAllListsForUser',
	EmployeeSentiment = 'employer/getEmployerInsights',
	IntegrationConnectionDetails = 'crm-integration/getIntegrationConnectionDetails',
	EmployeeSummary = 'employer/getEmployerSummary',
	BrokerContacts = 'brokerContacts/search',
	EmployerContacts = '/employer/searchContacts',
	ContactsFromList = '/lists/getListItemsForList',
	GetBrokerOffices = 'broker/getBrokerOffices',
	BrokerContactsFilters = 'brokerContacts/searchResultsBreakdown',
}

export enum MutationPath {
	DeleteList = 'lists/deleteList',
}
