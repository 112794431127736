import { LocationSelection } from 'common.model/src/types/master/location';
import { IncludeExcludeField, Range } from './filters';

export enum BrokerOfficeTypeEnum {
	ReportedBrokerOffice = 'Reported Broker Office',
	ClosestBrokerOffice = 'Closest Broker Office',
}

export interface EmployerFilters {
	employerNameSearch: string;
	employerMasterId: string[];
    employerHQLocations: IncludeExcludeField<LocationSelection[]>;
	entityTypes: IncludeExcludeField<string[]>;
    planTypes: IncludeExcludeField<string[]>;
    lineOfBusiness: IncludeExcludeField<string[]>;
    participantRanges: Range;
    renewalDatesRanges: Range;
    industries: IncludeExcludeField<string[]>;	
	carrierName: IncludeExcludeField<string[]>;
	page: number;
}

export interface BrokerFilters extends EmployerFilters {
    brokerMasterId: string[];
    masterBrokerNameSearch: string;
    brokerOfficeLocations: IncludeExcludeField<LocationSelection[]>;
    filterPrimaryBroker: boolean;
    groupBy: BrokerOfficeTypeEnum;
}

export interface ProviderFilters {
	states: string[];
	search: string;
};
  

// Create an empty object for filters BrokerFilters
let getEmptyEmployerFilters = (): EmployerFilters => ({
	employerNameSearch: '',
	employerMasterId: [],
	employerHQLocations: { include: [], exclude: [] },
	entityTypes: { include: [], exclude: [] },
	planTypes: { include: [], exclude: [] },
	lineOfBusiness: { include: [], exclude: [] },
	participantRanges: { start: 0, end: 3000000 },
	renewalDatesRanges: { start: 1, end: 12 },
	industries: { include: [], exclude: [] },
	carrierName: { include: [], exclude: [] },
	page: 0
});

let getEmptyBrokerFilters = (): BrokerFilters => ({
	...getEmptyEmployerFilters(),
	brokerMasterId: [],
	masterBrokerNameSearch: '',
	brokerOfficeLocations: { include: [], exclude: [] },
	filterPrimaryBroker: false,
	groupBy: BrokerOfficeTypeEnum.ClosestBrokerOffice
});

let getEmptyProviderFilters = (): ProviderFilters => ({
	states: [],
	search: ''
});

export { getEmptyEmployerFilters, getEmptyBrokerFilters, getEmptyProviderFilters };