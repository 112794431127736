import { BrokerContactsFiltersResponse } from 'new-beginning/queries/useBrokerContactsFiltersQuery.js';
import { ContactDTO } from 'new-beginning/queries/index.js';
import { BrokerContactLocationDTO } from '../../../../queries/useBrokerContactLocationsQuery.js';
import { BrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters.js';

export const COLLAPSIBLE_MAP_KEYS = [
	'companyName',
	'contactLocation',
	'role',
	'jobTitle',
	'yearsAtCompany',
	'licenseTypes',
	'carrierAppointments',
	'brokerOfficeLocations',
	'brokerSize',
] as const;

export interface BrokerContactsStore extends ContactSelectorMethods {
	isAddToListDialogOpen: boolean;
	setIsAddToListDialogOpen: (isOpen: boolean) => void;
	filters: BrokerContactFilters;
	setFilters: (filters: BrokerContactFilters) => void;
	setFilter: Record<
		keyof Omit<BrokerContactFilters, 'yearsAtCompany' | 'nameSearch' | 'contactLocation' | 'brokerOfficeLocations' | 'nameSearch'>,
		Record<'include' | 'exclude', Record<'add' | 'remove', (...value: string[]) => void>>
	> &
		Record<
			'contactLocation' | 'brokerOfficeLocations',
			{
				include: {
					add: ({ location, radiusMiles }: { location: BrokerContactLocationDTO; radiusMiles?: number }) => void;
					remove: (location: Pick<BrokerContactLocationDTO, 'searchValue'>) => void;
				};
				exclude: {
					add: ({ location, radiusMiles }: { location: BrokerContactLocationDTO; radiusMiles?: number }) => void;
					remove: (location: Pick<BrokerContactLocationDTO, 'searchValue'>) => void;
				};
			}
		> & {
			nameSearch: (value: string) => void;
		} & {
			yearsAtCompany: Record<'min' | 'max', (value: string) => void>;
		};
	collapsible: {
		map: Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>;
		toggle: Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], () => void>;
		clear: () => void;
	};
	setState: (state: Partial<BrokerContactsStore>) => void;
}

export interface BrokerContactContext {
	contacts: ContactDTO[];
	filterOptions: {
		data: Partial<BrokerContactsFiltersResponse>;
		loading?: boolean;
	};
	numberOfSelectedContacts: number;
	totalNumberOfContacts: null | number;
	loading: boolean;
	fetching: boolean;
	fetchNextPage: () => void;
	hasNextPage: boolean;
	sidebarDimensions: {
		height: number;
		width: number;
	};
}
