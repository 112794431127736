import { useEffect, useMemo } from 'react';
import { InfiniteData } from '@tanstack/react-query';

import { ContactSelectorMethods } from '../utilities/StoreUtils/createContactSelectorMethods';
import { ContactsDTO } from '../queries';

export const useContacts = <Store extends ContactSelectorMethods>({ data, store }: { data: InfiniteData<ContactsDTO>; store: Store }) => {
	const contacts = useMemo(() => {
		return (
			data?.pages
				?.flatMap((page) => page.data)
				?.map((contact) => {
					const purchasedContact = store.visibleContactsPurchasedDurringSession[contact.id];
					if (purchasedContact)
						return {
							...contact,
							...purchasedContact,
						};
					return contact;
				}) ?? []
		);
	}, [data, store.visibleContactsPurchasedDurringSession]);

	useEffect(() => {
		if (store.checkMode === 'select-all')
			contacts.forEach((contact) => {
				if (store.checkedMap[contact.id] === undefined) store.toggleCheckMap(contact.id);
			});
	}, [store.checkMode, store.checkedMap, store.toggleCheckMap, contacts]);

	return contacts;
};
