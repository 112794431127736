import cn from 'classnames';
import Switch from 'react-switch';
import { FaTrashAlt } from 'react-icons/fa';
import { IoShuffle } from 'react-icons/io5';
import { ImKey2 } from 'react-icons/im';
import { BsArrowRight } from 'react-icons/bs';
import { FieldMappingDropdown } from 'new-beginning/components/pages/IntegrationSettings/FieldMappings/SubComponents/FieldMappingDropdown';
import { SourceOrTarget, DropDownOptionType } from 'new-beginning/hooks/salesforce/useSalesforceConfigurationData';
import { CrmIntegrationFieldDomainType, ObjectMappingTargetType, DecoratedFieldMapping, StagedDecoratedFieldMapping } from 'common.model/src/db/model-decorators/type-extensions';

interface MappingTableRowProps {
	loading: boolean;
	crmObjectType: ObjectMappingTargetType;
	fieldMappings: (StagedDecoratedFieldMapping | DecoratedFieldMapping)[];
	sourceFieldSet: DropDownOptionType[];
	targetFieldSet: DropDownOptionType[];
	existingFieldEdits?: { [key: number]: DecoratedFieldMapping };
	setMappingField: (mappingIdx: number, values: Partial<DropDownOptionType>) => void;
	deleteFieldMapping: (index: number) => void;
}

export const MappingTableRows = ({
	loading,
	crmObjectType,
	fieldMappings,
	sourceFieldSet,
	targetFieldSet,
	existingFieldEdits,
	setMappingField,
	deleteFieldMapping,
}: MappingTableRowProps) => {
	return fieldMappings && fieldMappings.length > 0
		? fieldMappings
				.map((mappingField, idx) => {
					const isExistingEdit = !!existingFieldEdits?.[mappingField?.id]
					const field = isExistingEdit ? existingFieldEdits?.[mappingField?.id] : mappingField;

					const {
						id,
						sourceField,
						targetField,
						// Old Columns
						allow_overwrite,
						matching_field,
						required_field,
					} = field;
					const picklistConstantSelected = sourceField?.field_domain_type === CrmIntegrationFieldDomainType.SALESFORCE_CONSTANT;
					
					return (
						<tr key={`${id || targetField?.name}-${idx}`}>
							<td>
								<FieldMappingDropdown
									fieldIdx={idx}
									updateFieldMapping={setMappingField}
									sourceOrTarget={SourceOrTarget.SOURCE}
									value={field?.sourceField}
									mappingField={field}
									optionSet={sourceFieldSet}
									isLocked={required_field === true}
									isEdit={isExistingEdit}
								/>
							</td>
							<td align='center'>
								{!matching_field && !sourceField?.value && <IoShuffle size={22} color='gray' />}
								{
									(matching_field)
										? <ImKey2 size={20} color='gray' />
										: null
								}
								{sourceField?.value ? <BsArrowRight size={22} color='gray' /> : null}
							</td>
							<td>
								<FieldMappingDropdown
									fieldIdx={idx}
									sourceOrTarget={SourceOrTarget.TARGET}
									value={field?.targetField}
									mappingField={field}
									optionSet={targetFieldSet}
									updateFieldMapping={setMappingField}
									isLocked={required_field || !sourceField?.name || picklistConstantSelected}
								/>
							</td>
							<td align='center'>
								<Switch
									onColor='#ebf0fc'
									onHandleColor='#185ADB'
									offColor='#ebf0fc'
									offHandleColor='#B7C2CF'
									height={25}
									handleDiameter={25}
									width={50}
									uncheckedIcon={false}
									checkedIcon={false}
									onChange={(value: boolean) => {
										setMappingField(idx, { allow_overwrite: value });
									}}
									checked={allow_overwrite}
								/>
							</td>
							<td align='right'>
								<button
									type='button'
									disabled={loading || required_field}
									onClick={() => {
										// Existing Fields are stored by ID | Unsaved Mappings are stored by Index
										deleteFieldMapping(id || idx);
									}}
									className={cn(
										'btn',
										loading || required_field ? 'btn-outline-secondary' : 'btn-outline-danger',
										'py-2',
										'mx-1'
									)}
								>
									<FaTrashAlt size={14} />
								</button>
							</td>
						</tr>
					);
				})
		: null;
};
