import { useContext } from 'react';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { APIContext } from 'new-beginning/services/api/Api.context';
import { BrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { ContactsApi, PurchaseContactsInBulkResponse } from '../services/api/clients/ContactsApi';

export type Params = Parameters<ContactsApi['purchaseContactsInBulk']>[0];
export type Response = PurchaseContactsInBulkResponse;

export const usePurchaseContactsInBulkMutation = (options?: UseMutationOptions<Response, unknown, Params>) => {
	const { contactsApi } = useContext(APIContext);
	const mutation = useMutation({
		mutationFn: contactsApi.purchaseContactsInBulk.bind(contactsApi),
		mutationKey: ['purchaseContactsInBulk'],
		...options,
	});
	return mutation;
};
