import { createContactSelectorMethods } from './createContactSelectorMethods';
import { createIncludeExcludeMethods } from './createIncludeExcludeMethods';
import { createLocationMethods } from './createLocationMethods';
import { createRangeMethods } from './createRangeMethods';

export const StoreUtils = {
	createIncludeExcludeMethods,
	createRangeMethods,
	createLocationMethods,
	createContactSelectorMethods,
};
