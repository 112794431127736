import React from 'react';
import styled from 'styled-components';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Form } from 'react-bootstrap';
import Title from 'atoms/Title';
import { terciaryColor } from 'designTokens/colors';
import { DropDown, OptionConfigProps } from 'atoms/filters/drop/DropDown';
import { CityStateDropDown } from 'atoms/filters/drop/CityStateDropDown';
import { IoIosPeople } from 'react-icons/io';
import { MdOutlineDesk } from 'react-icons/md';
import { BiTimeFive } from 'react-icons/bi';
import ControlledOpenUtils from 'pages/SearchPage/utils/ControlledOpenUtils';
import { BsFillBuildingFill } from 'react-icons/bs';
import { ContactTypeEnum } from 'common.model/src/types/ContactTypeEnum';
import { DropDownRangeSelector } from 'atoms/filters/dropdownrange/DropDownRangeSelector';
import { AllContactFilters as ContactFiltersType } from 'common.model/src/types/common/search/filters/ContactFilters';
import { BrokerFilters, EmployerFilters } from 'common.model/src/types/common/search/filters/CompanyFilters';

const Container = styled.div`
	.ico-group {
		display: grid;
		grid-template-columns: auto 1fr;
		align-items: flex-start;

		.ico {
			color: #0a1931;
			font-size: 18px;
			width: 35px;
		}
	}

	.form-label {
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 17px;
		margin-bottom: 0.5rem;
	}

	.multi-select {
		display: grid;

		& > .select__control {
			width: 100%;
			overflow: hidden;
		}
	}

	.styled-dropdown {
		display: grid;
	}
`;

export type FilterOptions = {
	[k in keyof ContactFiltersType | keyof EmployerFilters |  keyof BrokerFilters]: OptionConfigProps[];
};

export type ContactFiltersProps = {
	loading: boolean;
	filters: any;
	filterOptions: FilterOptions;
	onChange: any;
	filtersOpenMap: any;
	filtersSearchMap: any;
	onChangeFiltersOpenMap: (filtersOpenMap: any) => void;
	onChangeFiltersSearchMap: (filtersSearchMap: any) => void;
	companyFilterEnabled?: boolean;
	cityStateSearch?: CityStateSearchProps;
	title: string;
	locationLabel: string;
	locationDescription: string;
	locationFilterName: string;
	contactType: ContactTypeEnum;
};

export type CityStateSearchProps = {
	cityStateSearchOptions: any[];
	isCityStateSearchOptionsLoading: boolean;
	onSearchCityState: (search: string) => void;
};

class ContactFilters extends React.Component<ContactFiltersProps, any> {
	updateFilters = (field, value) => {
		switch (field) {
			case 'companyName':
			case 'contactLocation':
			case 'officeLocation':
			case 'roles':
			case 'jobTitles':
			case 'yearsAtCompany':
			case 'industries':
			case 'entityTypes':
			case 'planTypes':
			case 'brokerSize':
				this.props.onChange(
					{
						...this.props.filters,
						[field]: value,
					},
					field,
				);
				break;
			default:
				break;
		}
	};

	updateYearsAtCompanyFilter = (value: string, rangeType: 'min' | 'max') => {
		const yearsAtCompany = this.props.filters.yearsAtCompany.slice();
		yearsAtCompany[rangeType === 'min' ? 0 : 1] = value;
		yearsAtCompany.sort((a, b) => a - b);
		this.updateFilters('yearsAtCompany', yearsAtCompany);
	};

	getYearsAtCompanyOptions = (rangeType: 'min' | 'max') => {
		const allOptions = Array.from({ length: 22 }, (_, i) => i.toString()).map((x) => {
			let formattedOption = x;
			if (x == '0') {
				formattedOption = 'No min';
			}
			if (x == '21') {
				formattedOption = 'No max';
			}
			return {
				option: x,
				formattedOption,
			};
		});
		if (rangeType === 'min') {
			return allOptions.slice(0, -1);
		}
		return allOptions.slice(1);
	};

	getYearsAtCompanyValues = (rangeType: 'min' | 'max') => {
		let idx = 0;
		let endValue = '0';
		let formattedEndValue = 'No min';
		if (rangeType === 'max') {
			idx = 1;
			endValue = '21';
			formattedEndValue = 'No max';
		}
		const currentValue = [this.props.filters.yearsAtCompany[idx]?.toString() ?? endValue];
		return currentValue.map((v) => ({ value: v, formattedValue: v == endValue ? formattedEndValue : v }));
	};

	componentDidUpdate(_: ContactFiltersProps) {
		if (this.props.cityStateSearch) {
			const { cityStateSearchOptions, onSearchCityState, isCityStateSearchOptionsLoading } = this.props.cityStateSearch;
			const emptySearchOptions = cityStateSearchOptions == null || cityStateSearchOptions == undefined;
			if (emptySearchOptions && !this.props.filtersSearchMap['BROKER_CONTACT_LOCATION'] && !isCityStateSearchOptionsLoading) {
				onSearchCityState('');
			}
		}
	}

	getControlledOpen(key: string) {
		return ControlledOpenUtils.getControlledOpen(key, this.props.filtersOpenMap, this.props.onChangeFiltersOpenMap);
	}

	getValues = (fieldName) => {
		return this.props.filters[fieldName] ? this.props.filters[fieldName].map((x) => (x == 'null' ? 'Not Specified' : x)) : [];
	};

	getOptions = (fieldName: keyof ContactFiltersType) => {
		return this.props.filterOptions?.[fieldName] ?? [];
	};

	render() {
		return (
			<Container>
				<Form>
					<Title className="lg" text={this.props.title} />

					{this.props.companyFilterEnabled && (
						<Form.Group className="ico-group" style={{ marginBottom: '2rem' }}>
							<i className="ico">
								<BsFillBuildingFill color={terciaryColor} size="20px" />
							</i>
							<div>
								<Form.Label>Company Name</Form.Label>
								<DropDown
									isDeleteable={true}
									values={this.props.filters?.['companyName']?.map((x) => ({
										value: x,
										formattedValue: x,
									}))}
									onChange={(values) => this.updateFilters('companyName', values)}
									options={this.getOptions('companyName')}
									title={'Select Company Name(s)'}
									controlledOpen={this.getControlledOpen('BROKER_COMPANY_SEARCH')}
									maxRenderedOptions={100}
								/>
							</div>
						</Form.Group>
					)}

					{this.props.cityStateSearch && (
						<Form.Group className="ico-group" style={{ marginBottom: '2rem' }}>
							<i className="ico">
								<FaMapMarkerAlt color={terciaryColor} size="20px" />
							</i>
							<div>
								<Form.Label>{this.props.locationLabel}</Form.Label>
								<CityStateDropDown
									title={this.props.locationDescription}
									options={this.props.cityStateSearch.cityStateSearchOptions ?? []}
									values={this.props.filters?.[this.props.locationFilterName]}
									isLoading={this.props.cityStateSearch.isCityStateSearchOptionsLoading}
									onChange={(values: any[]) => this.updateFilters(this.props.locationFilterName, values)}
									onSearch={this.props.cityStateSearch.onSearchCityState}
									controlledOpen={this.getControlledOpen('BROKER_CONTACT_LOCATION')}
									searchValue={this.props.filtersSearchMap['BROKER_CONTACT_LOCATION']}
									onChangeSearchValue={(searchValue: string) => {
										const updated = {
											...this.props.filtersSearchMap,
											['BROKER_CONTACT_LOCATION']: searchValue,
										};
										this.props.onChangeFiltersSearchMap(updated);
									}}
								/>
							</div>
						</Form.Group>
					)}

					<Form.Group className="ico-group" style={{ marginBottom: '2rem' }}>
						<i className="ico">
							<IoIosPeople color={terciaryColor} size="25px" />
						</i>
						<div>
							<Form.Label>Role</Form.Label>
							<DropDown
								isDeleteable={true}
								values={this.getValues('roles').map((x) => ({ value: x }))}
								onChange={(values) => this.updateFilters('roles', values)}
								options={this.getOptions('roles')}
								title={'Select Role(s)'}
							/>
						</div>
					</Form.Group>

					<Form.Group className="ico-group" style={{ marginBottom: '2rem' }}>
						<i className="ico">
							<MdOutlineDesk color={terciaryColor} size="25px" />
						</i>
						<div>
							<Form.Label>Job Title</Form.Label>
							<DropDown
								isDeleteable={true}
								values={this.getValues('jobTitles').map((x) => ({ value: x }))}
								onChange={(values) => this.updateFilters('jobTitles', values)}
								options={this.getOptions('jobTitles')}
								title={'Select Job Title'}
							/>
						</div>
					</Form.Group>

					<Form.Group className="ico-group" style={{ marginBottom: '2rem' }}>
						<i className="ico">
							<BiTimeFive color={terciaryColor} size="25px" />
						</i>
						<div>
							<Form.Label>Yrs. At Company</Form.Label>
							<DropDownRangeSelector
								lower={{
									onValueChange: (value) => this.updateYearsAtCompanyFilter(value, 'min'),
									options: this.getYearsAtCompanyOptions('min'),
									title: 'Select Years',
									value: this.getYearsAtCompanyValues('min')[0],
								}}
								upper={{
									onValueChange: (value) => this.updateYearsAtCompanyFilter(value, 'max'),
									options: this.getYearsAtCompanyOptions('max'),
									title: 'Select Years',
									value: this.getYearsAtCompanyValues('max')[0],
								}}
								spacerLabel={'to'}
							/>
						</div>
					</Form.Group>
				</Form>
			</Container>
		);
	}
}

export default ContactFilters;
