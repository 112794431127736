import { useContext } from 'react';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { APIContext } from 'new-beginning/services/api/Api.context';

import { ExportApi } from '../services/api/clients';
import { ClientData } from './types';

export const useExportStatusQuery = ({ exportId, options }: { exportId: string; options: UseQueryOptions<ClientData<ExportApi['getStatus']>> }) => {
	const { exportApi } = useContext(APIContext);
	const query = useQuery<ClientData<ExportApi['getStatus']>>(['exportStatus', exportId], () => exportApi.getStatus.bind(exportApi)(exportId), options);

	return query;
};
