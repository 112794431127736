import { ContactDTO } from '../../../queries';
import { EmployerContactFilters } from '../../../../app/containers/EmployerPage/types';
import { BrokerContactFilters } from 'common.services/src/requests/BrokerContactService';
import { ContactSelectorMethods } from '../../../utilities/StoreUtils/createContactSelectorMethods';

export enum Tabs {
	BROKER_CONTACTS = 'broker',
	EMPLOYER_CONTACTS = 'employer',
}

export interface ContactsListStore extends ContactSelectorMethods {
	tab: `${Tabs}`;
	setTab: (tab: `${Tabs}`) => void;
	setState: (state: Partial<ContactsListStore>) => void;
}

export interface ContactsListContext {
	filters: EmployerContactFilters | BrokerContactFilters;
	contacts: ContactDTO[];
	fetchNextPage: () => void;
	isFetching: boolean;
	hasNextPage: boolean;
	isLoading: boolean;
	isLoadingFilters: boolean;
	totalNumberOfContacts: number;
	numberOfSelectedContacts: number;
}
