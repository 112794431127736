import { useCallback, useMemo, useState } from 'react';
import { addNotification } from '@benefitflow/designsystem';

import { ExportStatus } from 'new-beginning/services/api/clients';
import { Notifications } from '../../../../../constants/notifications';
import { OnExportParameters } from '../../../../../context/ExportContext';
import { useExportMutation } from 'new-beginning/mutations/useExportMutation';
import { useExportDownloadLinkQuery, useExportStatusQuery } from 'new-beginning/queries';

export const useOnExport = () => {
	const [exportId, setExportId] = useState<string>();
	const [exportStatus, setExportStatus] = useState<ExportStatus>();

	const { mutate, isLoading } = useExportMutation({
		options: {
			onSuccess: ({ exportId, status }) => {
				setExportId(exportId);
				setExportStatus(status);
			},
			onError: (e) => {
				console.error(e);
			},
		},
	});

	const onExport = useCallback(
		(params: OnExportParameters) => {
			if (params.type === 'brokerContacts')
				mutate({
					params: {
						...params.filters,
						checkedMap: params.checkedMap,
						notCheckedMap: params.notCheckedMap,
						page: 0,
					},
					type: params.type,
				});
			if (params.type === 'brokerContactsList') {
				mutate({
					params: {
						filters: params.filters,
						contactsCheckedMap: params.checkedMap,
						contactsNotCheckedMap: params.notCheckedMap,
						isSelectAll: false,
						itemType: 'BROKER_CONTACT',
						listId: params.listId,
						page: 0,
					},
					type: params.type,
				});
			}
			if (params.type === 'employerContactsList') {
				mutate({
					params: {
						filters: params.filters,
						contactsCheckedMap: params.checkedMap,
						contactsNotCheckedMap: params.notCheckedMap,
						isSelectAll: false,
						itemType: 'EMPLOYER_CONTACT',
						listId: params.listId,
						page: 0,
					},
					type: 'brokerContactsList',
				});
			}
		},
		[mutate],
	);

	useExportStatusQuery({
		exportId,
		options: {
			enabled: !!exportId,
			onSuccess: (status) => {
				setExportStatus(status);
				if (status === 'FAILED') {
					addNotification(Notifications.Export.Error);
					setExportStatus(undefined);
					setExportId(undefined);
					return;
				}
			},
			refetchInterval: () => {
				if (!exportId) return false;
				if (exportStatus === 'COMPLETE') return false;
				if (exportStatus === 'FAILED') return false;
				if (exportStatus === 'DOWNLOADED') return false;
				return 1000;
			},
		},
	});

	useExportDownloadLinkQuery({
		exportId,
		options: {
			enabled: exportStatus === 'COMPLETE',
			onSuccess: (downloadLink) => {
				setExportStatus(undefined);
				setExportId(undefined);
				addNotification(Notifications.Export.Success);
				const link = document.createElement('a');
				link.href = downloadLink;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
		},
	});

	const isExporting = useMemo(() => !!exportId || isLoading, [exportId, isLoading]);

	return { onExport, isExporting };
};
