import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';

import { MutationOptions } from '../queries/types';
import { APIContext } from '../services/api/Api.context';
import { ListsApi } from '../services/api/clients/ListsApi';

export const useDeleteContactFromListMutation = ({ options }: { options?: MutationOptions<ListsApi['deleteContacts']> } = {}) => {
	const { listsApi } = useContext(APIContext);
	const mutation = useMutation<void, unknown, Parameters<ListsApi['deleteContacts']>[0]>({
		mutationFn: listsApi.deleteContacts.bind(listsApi),
		...options,
	});

	return mutation;
};
