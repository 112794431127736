import { addNotification } from '@benefitflow/designsystem';

import FormattingUtils from '../../ui-components/utils/FormattingUtils';

export type Notification = Parameters<typeof addNotification>[0];

export const Notifications = {
	Export: {
		Success: {
			type: 'success',
			message: 'Export completed.',
			autoClose: true,
		} satisfies Notification,
		Error: {
			type: 'error',
			message: 'Export failed, please try again.',
		} satisfies Notification,
	},
	ContactPurchase: {
		Success: ({
			totalCreditsRemaining,
			creditsJustUsed,
			totalCreditsAllowed,
		}: {
			creditsJustUsed: number;
			totalCreditsRemaining: number;
			totalCreditsAllowed: number;
		}) =>
			({
				type: 'success',
				autoClose: true,
				title: `${FormattingUtils.formatCommas(creditsJustUsed)} credits used.`,
				message: `Your team has ${FormattingUtils.formatCommas(totalCreditsRemaining)} of ${FormattingUtils.formatCommas(totalCreditsAllowed)} remaining.`,
			}) satisfies Notification,
		Error: {
			type: 'error',
			message: 'An error occurred while getting contacts.',
		} satisfies Notification,
		NoCharge: {
			autoClose: true,
			type: 'info',
			message: 'You only get charged for contacts with valid emails.',
		} satisfies Notification,
	},
	Search: {
		ByBrokers: {
			Contacts: {
				AddToListDialog: {
					Success: ({ name, totalAdded }: { totalAdded: number; name: string }) =>
						({
							type: 'success',
							title: `Added ${FormattingUtils.formatCommas(totalAdded)} contacts to list: `,
							message: name ?? '',
							autoClose: true,
						}) satisfies Notification,
					Error: {
						type: 'error',
						title: 'Error:',
						message: `Failed to add contacts to list.`,
					} satisfies Notification,
				},
				CreateListDialog: {
					InvalidField: {
						Name: {
							type: 'error',
							title: 'Invalid List Name:',
							message:
								'The list name you entered is not allowed. Please choose a name that starts with a letter or number and contains only letters, numbers, spaces, or hyphens.',
						} satisfies Notification,
					},
					Error: {
						title: 'Error:',
						message: 'An error occurred while creating the list.',
						type: 'error',
						autoClose: true,
					} satisfies Notification,
					Success: ({ name, totalAdded }: { name: string; totalAdded: string }) =>
						({
							title: `Added ${FormattingUtils.formatCommas(totalAdded)} net new contacts to new list:`,
							message: `${name}.`,
							type: 'success',
							autoClose: true,
						}) satisfies Notification,
				},
			},
		},
	},
};
