import { useQuery } from '@tanstack/react-query';
import { CrmConnectionDetails } from 'common.model/src/db/model-decorators/type-extensions';
import { QueryPath } from 'new-beginning/constants/path';
import { QueryKey } from 'new-beginning/constants/queryKey';
import { WithAuthenticatedContext } from 'new-beginning/context/AuthenticatedContext';
import { LoggedInUserContext, UserContextType } from 'new-beginning/context/LoggedInUser';

import { useContext } from 'react';

export const useIntegrationConnectionDetails = () => {
	const { httpClient } = useContext(WithAuthenticatedContext);
	const { authUser }: UserContextType = useContext(LoggedInUserContext);
	return useQuery(
		[QueryKey.IntegrationConnectionDetails, authUser?.tenantIdOverride ?? ''],
		() => httpClient.get<{ res: { data: CrmConnectionDetails } }>(QueryPath.IntegrationConnectionDetails),
		{
			select: ({ data }) => data.res.data,
			staleTime: 10 * 60 * 1000, //millis
			refetchOnWindowFocus: true,
		}
	);
};