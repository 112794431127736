import { useParams } from 'react-router-dom';

import { List, Tabs, Header } from './components';
import { useContactsQuery } from '../../../queries';
import { useContacts } from '../../../hooks/useContacts';
import { useContactsListStore } from './ContactsList.store';
import { ContactsListContext } from './ContactsList.context';
import { useClearStateOnTabChange, useNumberOfSelectedContacts, useSidebarDimensions, useSyncWithExternalTabStore } from './hooks';

export const ContactsList = ({
	filters,
	totalNumberOfContacts,
	isLoadingFilters,
	setExternalTabStore,
	sidebarRef,
}: {
	filters;
	totalNumberOfContacts: number;
	isLoadingFilters: boolean;
	setExternalTabStore: (tab: number) => void;
	sidebarRef: React.MutableRefObject<HTMLDivElement>;
}) => {
	const { listId } = useParams();
	const store = useContactsListStore();
	const { data, hasNextPage, isLoading, isFetching, fetchNextPage } = useContactsQuery({
		filters,
		type: store.tab,
		listId,
		options: {
			getNextPageParam: (lastPage) => {
				if (!lastPage?.anonymizedContacts?.length) return null;
				return lastPage.page + 1;
			},
			enabled: !!listId,
		},
	});
	const contacts = useContacts({ data, store });
	const numberOfSelectedContacts = useNumberOfSelectedContacts({ totalNumberOfContacts });
	const { height: sidebarHeight } = useSidebarDimensions(sidebarRef);
	useClearStateOnTabChange();
	useSyncWithExternalTabStore({ setExternalTabStore, tab: store.tab });

	return (
		<ContactsListContext.Provider
			value={{
				filters,
				isLoadingFilters,
				contacts,
				isFetching,
				isLoading,
				fetchNextPage,
				hasNextPage,
				totalNumberOfContacts,
				numberOfSelectedContacts,
			}}
		>
			<Tabs />
			<Header />
			<List minHeight={sidebarHeight} />
		</ContactsListContext.Provider>
	);
};
