import { useParams } from 'react-router-dom';
import { useCallback, useContext } from 'react';
import { addNotification } from '@benefitflow/designsystem';

import { useContactsListStore } from '../../../../../ContactsList.store';
import { ContactsListContext } from '../../../../../ContactsList.context';
import { Notifications } from '../../../../../../../../constants/notifications';
import { usePurchaseContactsInBulkMutation } from '../../../../../../../../mutations/usePurchaseContactsInBulkMutation';
import { getApiPayloadFromState } from '../../../../../../Search/BrokerContacts/components/List/components/Header/helpers/getApiPayloadFromState';

export const useGetSelected = () => {
	const { listId } = useParams();
	const { filters } = useContext(ContactsListContext);
	const { addPurchasedContact } = useContactsListStore();
	const onError = () => addNotification(Notifications.ContactPurchase.Error);
	const { mutate, isLoading: loading } = usePurchaseContactsInBulkMutation({
		onSuccess: (data) => {
			addPurchasedContact(...data.res.map(({ mobile_phone_1, profile_url, work_email }) => ({ email: work_email, phone: mobile_phone_1, id: profile_url })));
			addNotification(data?.consumption ? Notifications.ContactPurchase.Success(data.consumption) : Notifications.ContactPurchase.NoCharge);
		},
		onError,
	});
	const { checkMode, checkedMap, tab } = useContactsListStore();
	const getSelected = useCallback(() => {
		mutate({
			checkedMap,
			checkMode,
			...getApiPayloadFromState({ checked: checkedMap, checkMode }),
			filters,
			type: tab,
			listId,
		});
	}, [mutate, filters, checkedMap, checkMode, tab, listId]);

	return { getSelected, loading };
};
