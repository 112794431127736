import { Route, Routes } from 'react-router-dom';
import { AdminRoute } from 'routes/AdminRoute';
import { TeamView } from 'new-beginning/components/pages/Team/TeamView';
import { CrmConnectionPage } from 'new-beginning/components/pages/IntegrationSettings/ConnectionSettings/CrmConnectionPage';
import { FieldMappingSettings } from 'new-beginning/components/pages/IntegrationSettings/FieldMappings/FieldMappingSettings';
import { ListsSyncPageController as LegacySyncPage } from 'app/containers/ListsPage/containers/ListsSyncPage/Loadable';
import { IntegrationsPageController } from 'app/containers/IntegrationsPage/Loadable';
import { ClientsPageController } from 'app/containers/ClientsPage/Loadable';
import { EmployerProfile } from 'new-beginning/components/pages/EmployerProfile/EmployerProfile';

interface AdminRoutesProps {
    isAdmin: boolean;
    isAuthed: boolean;
    loading: boolean;
}

interface RootAdminRoutesProps {
    isRootAdmin: boolean;
    isAuthed: boolean;
    loading: boolean;
}

export const AdminRoutes = ({ isAdmin, isAuthed, loading }: AdminRoutesProps) => (
    <Routes>
        <Route path="/" element={<AdminRoute isAdmin={isAdmin} isAuthed={isAuthed} loadingAuth={loading} />} >
            {/* TODO: Remove Old sync route when Done */}
            <Route path="Lists/:listId/Sync" element={<LegacySyncPage />} />
            <Route path="Integrations/connection" element={<CrmConnectionPage />} />
            <Route path="integrations/crm/settings" element={<FieldMappingSettings />} />
            <Route path="Integrations" element={<IntegrationsPageController />}/>
            <Route path="Team" element={<TeamView />} />
        </Route>
    </Routes>
);

export const RootAdminRoutes = ({ isRootAdmin, isAuthed, loading }: RootAdminRoutesProps) => (
    <Routes>
        <Route path="/" element={<AdminRoute isAdmin={isRootAdmin} isAuthed={isAuthed} loadingAuth={loading} />} >
            <Route path="Clients" element={<ClientsPageController />} />
            <Route path="Employer/v2/:employerId" element={<EmployerProfile />} />
        </Route>
    </Routes>
);
