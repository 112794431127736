import { useParams } from 'react-router-dom';
import { useCallback, useContext } from 'react';

import { useList } from '../../../../../hooks';
import { addNotification } from '@benefitflow/designsystem';
import { useContactsListStore } from '../../../../../ContactsList.store';
import { ContactsListContext } from '../../../../../ContactsList.context';
import { useMoveContactsFromListMutation } from '../../../../../../../../mutations/useMoveContactsFromListMutation';
import { getApiPayloadFromState } from '../../../../../../Search/BrokerContacts/components/List/components/Header/helpers/getApiPayloadFromState';

export const useMoveSelected = ({ selectedListId, closeDialog }: { selectedListId: string; closeDialog: () => void }) => {
	const { listId } = useParams();
	const { checkMode, checkedMap, tab } = useContactsListStore();
	const { filters } = useContext(ContactsListContext);
	const list = useList();
	const { mutate, isLoading } = useMoveContactsFromListMutation({
		options: {
			onSuccess: ({ numberOfContactsThatWillBeMoved }) => {
				addNotification({
					type: 'success',
					autoClose: false,
					title: `Moved ${numberOfContactsThatWillBeMoved} net new contacts to list. `,
					message: `Contacts will appear in ${list?.name ? `"${list?.name}"` : 'the list'} in a few minutes`,
				});
				closeDialog();
			},
			onError: (e) => {
				if (e?.['message']) {
					addNotification({
						type: 'warning',
						autoClose: false,
						message: e?.['message'],
					});
					return;
				}
				addNotification({
					type: 'error',
					autoClose: false,
					title: 'Error moving contacts to list.',
					message: 'Please try again later.',
				});
			},
		},
	});

	const moveSelected = useCallback(() => {
		const { checkedMap: contactsCheckedMap, notCheckedMap: contactsNotCheckedMap } = getApiPayloadFromState({ checked: checkedMap, checkMode });
		mutate({
			contactsCheckedMap,
			contactsNotCheckedMap,
			filters,
			fromListId: listId,
			isSelectAll: checkMode === 'select-all',
			itemType: tab === 'broker' ? 'BROKER_CONTACT' : 'EMPLOYER_CONTACT',
			toListId: selectedListId,
		});
	}, [mutate, filters, checkedMap, checkMode, listId, tab, selectedListId]);

	return {
		moveSelected,
		loading: isLoading,
	};
};
