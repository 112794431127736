export const createIncludeExcludeMethods = <FilterType, T extends string | number>(filterName: keyof FilterType, set) => ({
	include: {
		add: (...values: T[]) =>
			set((state) => ({
				filters: {
					...state.filters,
					[filterName]: {
						...state.filters[filterName],
						include: [...new Set([...state.filters[filterName].include, ...values])],
					},
				},
			})),
		remove: (...values: T[]) =>
			set((state) => ({
				filters: {
					...state.filters,
					[filterName]: {
						...state.filters[filterName],
						include: state.filters[filterName].include.filter((item) => !values.includes(item)),
					},
				},
			})),
	},
	exclude: {
		add: (...values: T[]) =>
			set((state) => ({
				filters: {
					...state.filters,
					[filterName]: {
						...state.filters[filterName],
						exclude: [...new Set([...state.filters[filterName].exclude, ...values])],
					},
				},
			})),
		remove: (...values: T[]) =>
			set((state) => ({
				filters: {
					...state.filters,
					[filterName]: {
						...state.filters[filterName],
						exclude: state.filters[filterName].exclude.filter((item) => !values.includes(item)),
					},
				},
			})),
	},
});
