type RangeFilter = [string, string];

export const createRangeMethods = <FilterType extends { filters: Record<string, any> }, K extends string & keyof FilterType['filters']>(
	filterName: K,
	set: (fn: (state: FilterType) => Partial<FilterType>) => void,
) => ({
	min: (min: string) =>
		set(
			(state) =>
				({
					filters: {
						...state.filters,
						[filterName]: [min, state.filters[filterName][1]] as RangeFilter,
					},
				}) as Partial<FilterType>,
		),
	max: (max: string) =>
		set(
			(state) =>
				({
					filters: {
						...state.filters,
						[filterName]: [state.filters[filterName][0], max] as RangeFilter,
					},
				}) as Partial<FilterType>,
		),
	setRange: (min: string, max: string) =>
		set(
			(state) =>
				({
					filters: {
						...state.filters,
						[filterName]: [min, max] as RangeFilter,
					},
				}) as Partial<FilterType>,
		),
});
