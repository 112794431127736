import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';

import { APIContext } from 'new-beginning/services/api/Api.context';
import { ExportApi } from 'new-beginning/services/api/clients';
import { MutationOptions } from 'new-beginning/queries/types';

export const useExportMutation = ({ options }: { options?: MutationOptions<ExportApi['createExport']> }) => {
	const { exportApi } = useContext(APIContext);
	const mutation = useMutation({ mutationFn: exportApi.createExport.bind(exportApi) as ExportApi['createExport'], ...options });

	return mutation;
};
