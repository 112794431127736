import { ContactDTO } from '../../queries';

export interface ContactSelectorMethods {
	checkMode: 'single-select' | 'select-all';
	setCheckMode: (mode: 'single-select' | 'select-all', contacts: ContactDTO[]) => void;
	toggleCheckMode: (contacts: ContactDTO[]) => void;

	checkedMap: Record<string, boolean>;
	setCheckMap: (map: Record<string, boolean>) => void;
	toggleCheckMap: (id: string) => void;

	visibleContactsPurchasedDurringSession: Record<string, Pick<ContactDTO, 'phone' | 'email'>>;
	addPurchasedContact: (...contacts: Pick<ContactDTO, 'phone' | 'email' | 'id'>[]) => void;
}

export const createContactSelectorMethods = <Store extends ContactSelectorMethods>(
	set: (fn: (state: Store) => Partial<Store>) => void,
): ContactSelectorMethods => {
	return {
		checkedMap: {},
		setCheckMap: (checkedMap: Record<string, boolean>) => {
			set((state) => ({
				...state,
				checkedMap,
			}));
		},
		toggleCheckMap: (id: string) => {
			set((state) => ({
				...state,
				checkedMap: {
					...state.checkedMap,
					[id]: !state.checkedMap[id],
				},
			}));
		},
		checkMode: 'single-select' as const,
		setCheckMode: (checkMode: 'single-select' | 'select-all', contacts: ContactDTO[]) => {
			set((state) => ({
				...state,
				checkMode,
				checkedMap: (() => {
					if (checkMode === 'select-all') {
						return contacts.reduce(
							(acc, cur) => ({
								...acc,
								[cur.id]: true,
							}),
							{},
						);
					}
					return {};
				})(),
			}));
		},
		toggleCheckMode: (contacts: ContactDTO[]) => {
			set((state) => ({
				...state,
				checkMode: state.checkMode === 'single-select' ? 'select-all' : 'single-select',
				checkedMap: (() => {
					if (state.checkMode === 'single-select') {
						return contacts.reduce(
							(acc, cur) => ({
								...acc,
								[cur.id]: true,
							}),
							{},
						);
					}
					return {};
				})(),
			}));
		},
		visibleContactsPurchasedDurringSession: {},
		addPurchasedContact: (...contacts: Pick<ContactDTO, 'phone' | 'email' | 'id'>[]) => {
			set((state) => ({
				...state,
				visibleContactsPurchasedDurringSession: (() => {
					return contacts.reduce(
						(acc, { id, email, phone }) => ({
							...acc,
							[id]: { email, phone },
						}),
						{},
					);
				})(),
			}));
		},
	};
};
