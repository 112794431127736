import { PaginationParams } from "common.model/src/types/Pagination";
import {
  AccountInsertPayload,
  AccountUpdatePayload,
  ContactInsertPayload,
  ContactUpdatePayload,
  SalesforceAccountsById,
  SalesforceContactWithAccount,
} from "../../types/services/crm-integration-service/salesforce";
import {
  ContactInfoAttributes,
  SalesforceRefreshJobItemAttributes,
  SalesforceSobjectAccountCreationAttributes,
  SalesforceSobjectContactCreationAttributes,
  SalesforceSobjectAccountAttributes,
  MasterContactSobjectCreationAttributes,
  SalesforceRefreshJobItemCreationAttributes,
  SalesforceContactDiffJobItemCreationAttributes,
  SalesforceContactSyncJobItemCreationAttributes,
  // Model Instance Types
  ListItemInstance,
  ListJobInstance,
  ListJobMessageLogInstance,
  SalesforceSobjectContactInstance,
  SalesforceRefreshJobItemInstance,
  SalesforceContactDiffJobItemInstance,
  SalesforceContactDiffJobItemAttributes,
  SalesforceContactSyncJobItemInstance,
  ContactInfoInstance,
  SalesforceAccountDiffJobItemCreationAttributes,
  BrokerContactOfficeInfoAttributes,
  SalesforceAccountDiffJobItemInstance,
  SalesforceAccountSyncJobItemInstance,
  SalesforceAccountSyncJobItemCreationAttributes,
  SalesforceSobjectAccountInstance,
  SalesforceSobjectUserCreationAttributes,
  ObjectFieldMappingAttributes,
  ObjectFieldMappingCreationAttributes,
  ObjectFieldMappingInstance,
} from "../models";
import { Op, WhereOptions } from "sequelize";

// General Type Utilities START
type ClassAttributesByType<T, V> = {
  [K in keyof T as T[K] extends V ? K : never]: T[K];
};
type ClassMethods<T> = ClassAttributesByType<T, Function>;
type SharedAttributes<S, T> = { [K in keyof S & keyof T]: T[K] };
// General Type Utilities END


export interface CrmConnectionDetails {
  connectionActive: boolean;
  crmType: "SALESFORCE" | "HUBSPOT";
  instanceUrl: string;
  establishedAt?: Date;
  lastPingedAt?: Date;
}

// List/List Item Types
export enum ContactItemType {
  BROKER_CONTACT = "broker",
  EMPLOYER_CONTACT = "employer",
}

// List Job Types
export type JobType = ListJobInstance["job_type"];
export type JobStatus = ListJobInstance["status"];

type TopLevelJobType = "parentJob" | "diffJob" | "syncJob";
export type GeneralizedJobTypeMap = Record<JobType, TopLevelJobType>;
export const JobTypeStatusMap: GeneralizedJobTypeMap = {
  "REFRESH-FULL": "parentJob",
  "REFRESH-PARTIAL": "parentJob",
  "GENERATE-DIFF": "diffJob",
  "BATCH-SYNC": "syncJob",
  "DIRECT-SYNC": "syncJob",
};

export interface ListSyncStatus {
  syncInProgress: boolean;
  currentJob?: ListJobInstance;
  parentJob?: ListJobInstance;
  diffJob?: ListJobInstance;
  syncJob?: ListJobInstance;
}

export type ChildJobTypeMap = Record<JobType, JobType | null>;

export const ChildJobType: ChildJobTypeMap = {
  "REFRESH-PARTIAL": "GENERATE-DIFF",
  "REFRESH-FULL": "GENERATE-DIFF",
  "GENERATE-DIFF": "BATCH-SYNC",
  "BATCH-SYNC": null,
  "DIRECT-SYNC": null,
};

type AllListJobProcessMethods = ClassMethods<ListJobInstance>;
type ProcessMethods = {
  _processRefreshJob: Function;
  _processDiffJob: Function;
};
type ListJobProcessMethods = keyof SharedAttributes<AllListJobProcessMethods, ProcessMethods>;
export type JobProcessMethodMap = Record<JobType, ListJobProcessMethods | null>;

export const JobProcessMethod: JobProcessMethodMap = {
  "REFRESH-PARTIAL": "_processRefreshJob",
  "REFRESH-FULL": "_processRefreshJob",
  "GENERATE-DIFF": "_processDiffJob",
  "BATCH-SYNC": null,
  "DIRECT-SYNC": null,
};

export enum ContactListFilter {
  ALL = "All",
  NEW = "Net-New",
  UPDATES = "Updates",
  SYNCED = "Synced",
}

export type ContactDiffOperationType = SalesforceContactDiffJobItemAttributes["action_type"];
type SalesforceSyncType = "INSERT" | "UPDATE";
export type ContactDiffSyncByType = Record<SalesforceSyncType, SalesforceContactDiffJobItemInstance[]>;
export type AccountDiffSyncByType = Record<SalesforceSyncType, SalesforceAccountDiffJobItemInstance[]>;

export type AccountSyncPayload = SalesforceAccountDiffJobItemInstance | {
  action_type: ContactDiffOperationType,
  merged_account_payload: {
    Id: string;
    ParentId: string;
  },
};

export interface SalesforceRelatedAccounts {
  accountLogo?: string;
  relatedAccounts: SalesforceSobjectAccountInstance[];
}

export type RelatedSalesforceAccountsByWebsite = Record<string, SalesforceRelatedAccounts>;

export interface ParentAccountAssignInstance {
  accountWebsite?: string;
  salesforceInstanceUrl?: string;
  relatedOrphanAccounts?: SalesforceRelatedAccounts;
  relatedParentAccounts?: SalesforceRelatedAccounts;
}
export interface OrphanAccountInstance extends ParentAccountAssignInstance {
  childAccountsToAssign: SalesforceSobjectAccountInstance[];
}

export interface AccountInsertAssignInstance extends ParentAccountAssignInstance {
  childAccountsToAssign: SalesforceAccountDiffJobItemInstance[];
}

export type ChildAccountAssignType = "INSERT" | "UPDATE";

export type AccountAssignmentMemo = Record<string, OrphanAccountInstance>;
export type InsertAccountAssignmentMemo = Record<string, AccountInsertAssignInstance>;
export type SyncAccountsRequiringParent = {
  existingAccounts: OrphanAccountInstance[];
  accountInserts: AccountInsertAssignInstance[];
}

export type ContactTabCounts = Record<ContactListFilter, number>;
export type ContactFilterToDiffOptions = Record<ContactListFilter, WhereOptions<SalesforceContactDiffJobItemAttributes>>;

export type ContactFilterToSyncFilter = Record<ContactListFilter, ContactDiffOperationType | ContactDiffOperationType[]>;
export const ListSyncFilterMap: ContactFilterToSyncFilter = {
  [ContactListFilter.ALL]: ["INSERT", "UPDATE"],
  [ContactListFilter.NEW]: "INSERT",
  [ContactListFilter.UPDATES]: "UPDATE",
  [ContactListFilter.SYNCED]: "UPDATE",
};

export interface ListContactType {
  id: number;
  list_item_id: string;
  item_type: ContactItemType;
  salesforce_contact_uid?: string;
  salesforce_contact_url?: string;
  salesforce_account_uid?: string;
  salesforce_account_url?: string;
  salesforce_staged_account_url?: string;
  salesforceInstanceUrl?: string;
  isSynced: boolean;
  sObjectContact: SalesforceSobjectContactInstance;
  accountDiffItem?: SalesforceAccountDiffJobItemInstance;
  accountSyncItem?: SalesforceAccountSyncJobItemInstance;
  contactDiffItem?: SalesforceContactDiffJobItemInstance;
  contactSyncItem?: SalesforceContactSyncJobItemInstance;
  contact: {
    id: string;
    name: string;
    jobTitle: string;
    generalTitle: string;
    avatarImgUrl: string;
    emailAddress: string;
    linkedinUrl: string;
    contactOwner: string;
    workPhoneNumber: string;
    cellNumber: string;
    city: string;
    state: string;
    country: string;
  };
  account: {
    company: {
      id: string;
      companyName: string;
      companyProfileUrl: string;
      website: string;
      logoUrl: string;
    };
    office: {
      location: string;
      streetAddress: string;
      city: string;
      state: string;
      zipCode: string;
    };
  };
}

export interface PaginatedContacts {
  contacts: ListContactType[];
  pagination: PaginationParams;
}

export interface RefreshJob {
  job: ListJobInstance;
  refreshItems: SalesforceRefreshJobItemInstance[];
}

// Refresh Job types used before creation of RefreshItem Rows START
export interface BaseContactRefresh {
  email: string;
  listItemLinkedinUrl: string;
  companyWebsite: string;
}

export interface UnsyncedExistingContact extends BaseContactRefresh {
  salesforceContact: SalesforceContactWithAccount;
}

export interface SyncedExistingContact extends UnsyncedExistingContact {
  existingMasterContactId: number;
}

export interface SyncResultWithAccountDiff {
  contactSyncItems: SalesforceContactSyncJobItemInstance[];
  accountDiffIdsToAssign: number[]
}

export type RefreshContactType = Partial<SyncedExistingContact> & {
  salesforceContact?: SalesforceContactWithAccount;
  existingMasterContactId?: number;
};

export type RefreshItemCreationUniqueMemo = Record<string, RefreshJobItemCreation>;

export interface EmailToItemMap {
  [email: string]: BaseContactRefresh;
}

export interface UnpersistedRefreshGrouping {
  unsyncedExistingContacts: UnsyncedExistingContact[];
  listItemsMatched: Record<string, BaseContactRefresh>;
}

export interface UnpersistedRefreshSet {
  unsyncedExistingContacts: UnsyncedExistingContact[];
  netNewContacts: BaseContactRefresh[];
  listItemsMissingMatchingCriteria: BaseContactRefresh[];
}

export interface ContactRefreshData {
  parentAccountsById: SalesforceAccountsById;
  unpersistedRefreshContacts: UnpersistedRefreshSet;
  persistedRefreshContacts: SyncedExistingContact[];
}

export interface StagedRefreshSet {
  emailToListItemMap: EmailToItemMap;
  listItemsMissingMatchingCriteria: BaseContactRefresh[];
  contactEmailsToFetch: string[];
}

export interface ListItemsForRefresh {
  persistedRefreshItems: ListItemInstance[];
  unpersistedRefreshItems: ListItemInstance[];
}
// Refresh Job types before creation of RefreshItem Rows END

export interface PaginatedListItems {
  listItems: ListItemInstance[];
  pagination: PaginationParams;
}

export interface PaginatedListContacts {
  pagination: PaginationParams;
  contacts: ListContactType[];
}

export interface AdjacentCompanyFilter {
  adjacentCompanyDomains: string[];
  accountIdsToIgnore?: string[];
}

export type RefreshItemType = SalesforceRefreshJobItemAttributes["refresh_type"];

export type RefreshItemsBaseType = Record<RefreshItemType, SalesforceRefreshJobItemInstance[]>;

export interface RefreshItemsByType extends RefreshItemsBaseType {
  LIST_ITEM_IDS: string[];
}

export type RefreshJobItemCreation = Partial<SalesforceRefreshJobItemCreationAttributes>;

export interface AccountDiffItemBySyncStatus {
  accountDiffIdsRequiringAssign: number[];
  accountDiffsToSync: SalesforceAccountDiffJobItemInstance[];
};

export type AccountDiffItemCreation = Omit<SalesforceAccountDiffJobItemCreationAttributes, "id" | "deprecated">;
export type AccountDiffItemUpsert = Omit<SalesforceAccountDiffJobItemCreationAttributes, "id">;
export type AccountSyncItemCreation = Omit<SalesforceAccountSyncJobItemCreationAttributes, "id">;

export type ContactDiffItemCreation = Omit<SalesforceContactDiffJobItemCreationAttributes, "id" | "deprecated">;
export type ContactDiffItemUpsert = Omit<SalesforceContactDiffJobItemCreationAttributes, "id">;
export type ContactSyncItemCreation = Omit<SalesforceContactSyncJobItemCreationAttributes, "id">;

export type RefreshItemStatus = SalesforceRefreshJobItemAttributes["status"];

export interface SalesforceContactsToRehydrate {
  syncItems: SalesforceContactSyncJobItemInstance[];
  sobjectContactIdsToRehydrate: string[];
  contactProfileUrlBySfUid: Record<string, string>;
}

export interface SalesforceAccountsToRehydrate {
  syncItems: SalesforceAccountSyncJobItemInstance[];
  sobjectAccountIdsToRehydrate: string[];
  updatedAccountDiffs: SalesforceAccountDiffJobItemInstance[];
}

export interface AccountInsertPreSyncs {
  parentAccountDiffsToSync: SalesforceAccountDiffJobItemInstance[];
  contactAccountDiffsToSync: SalesforceAccountDiffJobItemInstance[];
  childAccountDiffIdsByParentId: Record<number, number[]>;
  associatedContactDiffIds: number[],
}

export type SobjectContactCreation = Omit<SalesforceSobjectContactCreationAttributes, "bf_id" | "bf_deprecated">;
export type SobjectAccountCreation = Omit<SalesforceSobjectAccountCreationAttributes, "bf_id" | "bf_deprecated">;
export type SobjectUserCreation = Omit<SalesforceSobjectUserCreationAttributes, "bf_id" | "bf_deprecated">;

export enum ObjectMappingRuleType {
  BROKER = "BROKER",
  EMPLOYER = "EMPLOYER",
  SHARED = "SHARED",
}

export enum ObjectMappingTargetType {
  PARENT_ACCOUNT = "PARENT_ACCOUNT",
  CHILD_ACCOUNT = "CHILD_ACCOUNT",
  CONTACT = "CONTACT",
}

export enum CrmIntegrationFieldDomainType {
  BENEFIT_FLOW = 'BENEFIT_FLOW',
  SALESFORCE = 'SALESFORCE',
  SALESFORCE_CONSTANT = 'SALESFORCE_CONSTANT',
  CUSTOM_CONSTANT = 'CUSTOM_CONSTANT'
}

export type ObjectMappingByRuleType = Partial<Record<keyof ObjectMappingRuleType, DecoratedFieldMapping[]>>;

interface BaseDecoratedMapping {
  sourceField: {
		field_domain_type: CrmIntegrationFieldDomainType;
		name: string | null;
		value: string | null;
	}
	targetField: {
		field_domain_type: CrmIntegrationFieldDomainType.SALESFORCE | null;
		name: string | null;
	}
}

export interface DecoratedFieldMapping extends BaseDecoratedMapping, ObjectFieldMappingAttributes {}
export interface StagedDecoratedFieldMapping extends BaseDecoratedMapping, Omit<ObjectFieldMappingCreationAttributes, "tenant_id"> {}

// Fields valid for display in ContactDiffItem.field_changelog Object (based on ContactInfo Table)
type ContactInfoMappableFields = Omit<
  ContactInfoAttributes,
  | "contact_type"
  | "company_master_id"
  | "company_profile_url"
  | "employee_profile_url"
  | "employee_location"
  | "total_months_at_company"
  | "total_years_at_company"
  | "current_role_start_date"
  | "website"
  | "profile_pic"
  | "zerobounce_status"
  | "last_scrape_date"
>;
export type ValidFieldChangelogProperties = keyof ContactInfoMappableFields;

export const ItemStatusFromJob: Record<JobStatus, RefreshItemStatus> = {
  STAGED: "STAGED",
  "IN-PROGRESS": "STAGED",
  SUCCESS: "PROCESSED",
  CANCELED: "SKIPPED",
  FAILURE: "FAILED",
};

export interface GeneratedAccountHash {
  accountHash: string;
  hasLocation: boolean;
  normalizedAccountWebsite: string;
}

export interface AccountDiffItemRawAccount extends BrokerContactOfficeInfoAttributes {
  website: string;
  broker_name: string;
  company_name: string;
  company_master_id: string;
  office_state_code: string;
  office_zip_code: string;
  office_country_code: string;
  office_country_long: string;
}

export interface AccountIdentifierPayload {
  accountHash: string;
  baseCompanyName: string;
  account_name: string;
  account_type: "broker" | "employer";
  account_website_url: string;
  account_city: string;
  account_state: string;
  account_state_code: string;
  benefit_flow_account_raw: AccountDiffItemRawAccount;
}
type AccountHashToAccountDiff = Record<string, AccountIdentifierPayload>;

export type UniqueAccountHashMap = Record<string, true>;

export interface SalesforceAccountHashMemo {
  uniqueHashToAccount: Record<string, SalesforceSobjectAccountInstance>;
  accountHashByAccountUid: Record<string, string>;
  uniqueParentByWebsite: Record<string, SalesforceSobjectAccountInstance>;
  accountsExistByWebsite: Record<string, boolean>;
}

export interface FieldLevelChangeLog {
  previous: string | number | null;
  new: string | number | null;
}


export interface ComputedAccountDiff {
  merged_account_payload?: MergedAccountPayload;
  field_changelog?: Record<string, FieldLevelChangeLog>;
}

type PotentialFieldChangelogProperties = ValidFieldChangelogProperties | "AccountId" | "OwnerId";
export type ContactFieldChangeLog = Partial<Record<PotentialFieldChangelogProperties, FieldLevelChangeLog>>;
export type MergedContactPayload = ContactUpdatePayload | ContactInsertPayload;

export interface ComputedContactDiff {
  merged_contact_payload?: MergedContactPayload;
  field_changelog?: ContactFieldChangeLog;
  benefit_flow_contact_raw?: ListItemInstance;
}

export type MergedAccountPayload = AccountUpdatePayload | AccountInsertPayload;

export interface ContactAccountMatchingInput {
  itemId: number | string;
  contactType: "broker" | "employer";
  contactInfoObject: ContactInfoInstance;
}
export interface ContactAccountMatchingMemo {
  accountHashByItemId: Record<number | string, string>;
  uniqueAccountPayloadByHash: AccountHashToAccountDiff;
  uniqueWebsites: UniqueAccountHashMap;
  companyPayloadByWebsite: Record<string, AccountIdentifierPayload>;
}

type AccountCreationByAccountId = Record<string, SobjectAccountCreation>;

export interface AccountDiffsCreated {
  childAccountInserts: SalesforceAccountDiffJobItemInstance[],
  parentDiffIdByWebsite: Record<string, number>,
}

export interface AccountInsertSyncMemo {
  accountDiffIds: Record<number, boolean>;
  contactDiffsByAccountDiffId: Record<number, SalesforceContactDiffJobItemInstance[]>;
  numContactDiffIdsToRecompute: number;
}

export interface AccountCreationByType {
  parentAccounts: AccountCreationByAccountId;
  childAccounts: AccountCreationByAccountId;
}

export interface SobjectAccountsCreated {
  sobjectChildAccounts: SalesforceSobjectAccountInstance[];
  sobjectParentAccounts: SalesforceSobjectAccountInstance[];
}

export interface UniqueSobjectAccounts {
  parentAccounts: SobjectAccountCreation[];
  childAccounts: SobjectAccountCreation[];
}

export type AccountKeyMap = Record<"CHILD_ACCOUNT" | "PARENT_ACCOUNT", "sf_account_raw" | "sf_parent_account_raw">;
export type SalesforceAccountTypeMap = Record<"CHILD_ACCOUNT" | "PARENT_ACCOUNT", "Child Account" | "Parent Account">;

export const AccountTypeKey: AccountKeyMap = {
  CHILD_ACCOUNT: "sf_account_raw",
  PARENT_ACCOUNT: "sf_parent_account_raw",
};

export type MasterContactSobjectCreation = Omit<MasterContactSobjectCreationAttributes, "id">;

export const accountUpdateFields: (keyof Partial<SalesforceSobjectAccountAttributes>)[] = [
  "parent_internal_id",
  "account_type",
  "account_raw",
  "Website",
  "Name",
  "OwnerId",
  "ParentId",
  "Type",
  "MasterRecordId",
  "BillingCity",
  "BillingState",
  "BillingPostalCode",
  "ShippingCity",
  "ShippingState",
  "ShippingPostalCode",
  "AccountSource",
  "Industry",
  "Description",
  "NumberOfEmployees",
  "Phone",
  "IsDeleted",
  "LastModifiedById",
  "LastModifiedDate",
  "SystemModstamp",
  "updatedAt",
];

export const accountUpdateFieldsOnConflict = Array.from(accountUpdateFields);

// SalesForce SObject Types END

// ListJobMessageLog Types
export interface JobLog {
  log_level: ListJobMessageLogInstance["log_level"];
  log_message: string;
}

export enum ListLogMessageType {
  // Generic Types
  STAGED = "STAGED",
  "IN-PROGRESS" = "IN-PROGRESS",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  CANCELED = "CANCELED",
  DEFAULT = "DEFAULT",
  CREATED = "CREATED",
  // Generic Failure
  UNCAUGHT_PROCESSING_ERROR = "UNCAUGHT_PROCESSING_ERROR",
  // RefreshJobFailures
  REFRESH_JOB_ITEM_CREATION_FAILED = "REFRESH_JOB_ITEM_CREATION_FAILED",
  REFRESH_SOBJECT_CONTACT_CREATION_FAILED = "REFRESH_SOBJECT_CONTACT_CREATION_FAILED",
  REFRESH_SOBJECT_ACCOUNT_CREATION_FAILED = "REFRESH_SOBJECT_ACCOUNT_CREATION_FAILED",
  MASTER_SOBJECT_CONTACT_CREATION_FAILED = "MASTER_SOBJECT_CONTACT_CREATION_FAILED",
}

export enum JobLogLevel {
  ERROR = "ERROR",
  INFO = "INFO",
}

type TemplateLiteralFn = (listJob: ListJobInstance) => string;
export type ListLogTemplateMap = Record<ListLogMessageType, TemplateLiteralFn>;

const listJobLog = (listJob: ListJobInstance) => `${listJob}`;
const baseListJobMessageTemplate = (listJob: ListJobInstance) => `${listJobLog(listJob)} set to ${listJob?.status}`;

export const ListJobMessageLogTemplates: ListLogTemplateMap = {
  [ListLogMessageType.CREATED]: (listJob) => `${listJobLog(listJob)} CREATED`,
  [ListLogMessageType.DEFAULT]: baseListJobMessageTemplate,
  [ListLogMessageType.STAGED]: baseListJobMessageTemplate,
  [ListLogMessageType["IN-PROGRESS"]]: baseListJobMessageTemplate,
  [ListLogMessageType.SUCCESS]: baseListJobMessageTemplate,
  [ListLogMessageType.CANCELED]: baseListJobMessageTemplate,
  [ListLogMessageType.FAILURE]: (listJob) => `${listJobLog(listJob)} failed to process`,
  [ListLogMessageType.UNCAUGHT_PROCESSING_ERROR]: (listJob) => `${listJobLog(listJob)} failed to process`,
  // RefreshJob Failures
  [ListLogMessageType.REFRESH_JOB_ITEM_CREATION_FAILED]: (listJob) => `${listJobLog(listJob)} failed on creation of Refresh Job Items`,
  [ListLogMessageType.MASTER_SOBJECT_CONTACT_CREATION_FAILED]: (listJob) =>
    `${listJobLog(listJob)} failed on creation of Master Contact Sobjects`,
  [ListLogMessageType.REFRESH_SOBJECT_CONTACT_CREATION_FAILED]: (listJob) =>
    `${listJobLog(listJob)} failed on creation of SObject Contacts`,
  [ListLogMessageType.REFRESH_SOBJECT_ACCOUNT_CREATION_FAILED]: (listJob) =>
    `${listJobLog(listJob)} failed on creation of SObject Accounts`,
};
