import { FC, useState, useContext, useEffect} from 'react';
import { WithAuthenticatedContext } from 'new-beginning/context/AuthenticatedContext';
import cn from 'classnames';
import { FaSalesforce, FaHubspot } from "react-icons/fa";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIntegrationConnectionDetails } from 'new-beginning/hooks/salesforce/useIntegrationConnectionDetails';
import moment from 'moment';
import { SuccessPill, UpdatePill } from 'new-beginning/components/shared/Pills';
import serviceUrl from 'utils/serviceUrl';

const salesforceSetupLink = 'https://21206862.hs-sites.com/knowledge-base/how-do-i-setup-the-salesforce-integration';

interface CrmConnectionPageProps {}

export const CrmConnectionPage: FC<CrmConnectionPageProps> = () => {
  const { data: connectionDetails, isLoading } = useIntegrationConnectionDetails(); 
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { httpClient } = useContext(WithAuthenticatedContext);
  const [ newCrmConnectionType, setCrmConnectionType ] = useState<'SALESFORCE' | 'HUBSPOT'>(null);
  const navigate = useNavigate();
  const navigateFieldMappings = () => navigate('/Integrations/crm/Settings');

  useEffect(() => {
    const crmType = searchParams.get('crm_type');
    const crmAuthCode = searchParams.get('code');
    if (crmType && crmAuthCode) {
      console.log('CRM TYPE: ', crmType);
      console.log('CRM AUTH CODE: ', crmAuthCode);
      httpClient.get(`${serviceUrl}/crm-integration/hubspotOAuthCallback?code=${crmAuthCode}`);
    }
  }, [searchParams]);

  const isCrmConnected = connectionDetails?.connectionActive;
  const connectionType = connectionDetails?.crmType;
  const salesforceActive = isCrmConnected && connectionType === 'SALESFORCE';
  const hubspotActive = isCrmConnected && connectionType === 'HUBSPOT';

  const connectedAt = moment(connectionDetails?.establishedAt).format('MMMM Do YYYY');
  const lastPingedAt = moment(connectionDetails?.lastPingedAt).format('h:mm A [on] MM/DD/YY');

  return (
    <>
      <div className={cn('mx-2', 'py-2')}>
        <div className={cn('bf-header', 'sync-page-header', 'p-3', 'mb-2', 'rounded', 'bg-white', 'w-100')}>
          <p className={cn('bf-txt', 'txt-xl', 'txt-bold', 'txt-dark')}>CRM Connection Configuration</p>
          <p className={cn('bf-txt', 'txt-sm', 'txt-neutral')}>Connect BenefitFlow to your CRM in minutes.</p>
        </div>
        <div className={cn('d-flex', 'mx-3')}>
          <div className={cn('d-flex', 'flex-column','my-1', 'me-3', 'p-3', 'bg-white', 'rounded')}>
            <div className={cn('d-flex', 'align-items-center', 'mb-2')}>
              <FaSalesforce size={55} className={cn('bf-icon', 'ic-salesforce')} />
              <p className={cn('bf-txt', 'txt-lg', 'ms-3', 'txt-bolder', 'txt-neutral-off')}>Salesforce Connection</p>
            </div>
            <div className={cn('mb-2')}>
              {salesforceActive ? (
                <div>
                  <a
                    className={cn('ps-2')}
                    href={connectionDetails?.instanceUrl}
                    target='_blank'
                  >
                    Active Salesforce Instance
                  </a>
                  <div className={cn('bf-txt', 'txt-md')}>
                    <div className={cn('my-2')}>
                      <SuccessPill isVisible label={`Connection Established: ${connectedAt}`} />
                    </div>
                    <div className={cn('my-1')}>
                      <UpdatePill isVisible label={`Last Pinged: ${lastPingedAt}`} />
                    </div>
                  </div>
                </div>
              ) : (
                <span>
                  Follow our&nbsp;
                  <a target='_blank' href={salesforceSetupLink}>Salesforce Setup Guide</a>
                  &nbsp;to begin syncing.
                </span>
              )}

            </div>
            <div className={cn('mt-3', 'mb-2')}>
              <button
                className={cn('btn', 'btn-primary', 'mt-2')}
                onClick={() => setCrmConnectionType('SALESFORCE')}
                disabled={isLoading || true}
              >
                {salesforceActive ? 'Update Connection' : 'Establish Connection'}
              </button>
              <button
                className={cn('btn', 'btn-primary', 'mt-2', 'ms-2')}
                onClick={navigateFieldMappings}
              >
                Integration Settings
              </button>
            </div>
          </div>
          <div className={cn('d-flex', 'flex-column', 'justify-content-between', 'my-1', 'p-3', 'bg-white', 'rounded')}>
            <div className={cn('d-flex', 'align-items-center', 'mb-2')}>
              <FaHubspot size={55} className={cn('bf-icon', 'ic-hubspot')} />
              <p className={cn('bf-txt', 'txt-lg', 'ms-3', 'txt-bolder', 'txt-neutral-off')}>Hubspot Connection</p>
            </div>
            <div className={cn('mb-2')}>
              <span>
                Connect your Hubspot account to begin syncing.
              </span>
            </div>
            <div className={cn('mt-3', 'mb-2')}>
              <button
                className={cn('btn', 'btn-primary', 'mt-2')}
                onClick={async () => {
                  setCrmConnectionType('HUBSPOT');
                  const authRes = await httpClient.get(`${serviceUrl}/crm-integration/authorizeHubspotApplication`);
                  const appInstallUrl = authRes?.data?.res?.data?.hubspotInstallUrl;
                  window.location.replace(appInstallUrl);
                }}
                disabled={isLoading}
              >
                Establish Connection
              </button>
              <button
                className={cn('btn', 'btn-primary', 'mt-2', 'ms-2')}
                onClick={navigateFieldMappings}
              >
                Integration Settings
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
    </>
  );
};
