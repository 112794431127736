import { ListsApi, List } from './ListsApi';
import { APIClient } from '../types/Client';

export class ListsApiHTTP implements ListsApi {
	constructor(readonly client: APIClient) {}

	async list(): Promise<List[]> {
		const response = await this.client.post<any, any>('/lists/getAllListsForUser', {});
		if (response.data.res.success) return response.data.res.data;
		throw new Error('Failed to create export.');
	}

	async addContacts(data: Parameters<ListsApi['addContacts']>[0]): ReturnType<ListsApi['addContacts']> {
		if (data.contactType !== 'broker') throw new Error('Only broker contacts are currently supported with this client.');
		const response = await this.client.post<any, any>('/brokerContacts/addSelectedToExistingList', data);
		if (response.data.res.success) return response.data.res.data;
		throw new Error('Failed to add contacts to list.');
	}

	async addContactsToNewList(data: Parameters<ListsApi['addContactsToNewList']>[0]): ReturnType<ListsApi['addContactsToNewList']> {
		if (data.contactType !== 'broker') throw new Error('Only broker contacts are currently supported with this client.');
		const response = await this.client.post<any, any>('/brokerContacts/addSelectedToNewList', data);
		if (response.data.res.success) return response.data.res.data;
		throw new Error('Failed to add contacts to list.');
	}

	async deleteContacts(data: Parameters<ListsApi['deleteContacts']>[0]): ReturnType<ListsApi['deleteContacts']> {
		const response = await this.client.post<any, any>('/lists/deleteListItems', data);
		if (response.data.res.success) return { numberOfContactsThatWillBeDeleted: response.data.res.data };
		throw new Error('Failed to delete contacts from list.');
	}

	async moveContacts(data: Parameters<ListsApi['moveContacts']>[0]): ReturnType<ListsApi['moveContacts']> {
		const response = await this.client.post<any, any>('/lists/moveListItems', data);
		if (response.data.res.success) return { numberOfContactsThatWillBeMoved: response.data.res.data };
		throw new Error(response.data.res.message);
	}
}
