import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ListDTO, useListsQuery } from '../../../../queries/useListsQuery';

export const useList = (): ListDTO | undefined => {
	const { listId } = useParams();
	const { data } = useListsQuery();
	const list = useMemo(() => data?.find((list) => list.id == listId), [listId, data]);

	return list;
};
