import { useCallback } from 'react';
import { useAddNotification } from '@benefitflow/designsystem';

import { Notification, Notifications } from '../constants/notifications';

export const useOnPurchaseContactSuccess = ({ callback }: { callback: (data: any) => void }) => {
	const addNotification = useAddNotification();
	const onSuccess = useCallback(
		({ data }: any) => {
			let notification: Notification;
			if (!data.res.success) notification = Notifications.ContactPurchase.Error;
			else {
				callback(data);
				if (data.res?.data?.consumption) notification = Notifications.ContactPurchase.Success(data.res.data.consumption);
				else notification = Notifications.ContactPurchase.NoCharge;
			}
			addNotification(notification);
		},
		[callback, addNotification],
	);

	return onSuccess;
};
