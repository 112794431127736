import { ReactNode, useContext, useMemo } from 'react';

import { APIContext } from './Api.context';
import { WithAuthenticatedContext } from '../../context/AuthenticatedContext';
import { ExportApiHTTP } from './clients/ExportApi.http';
import { ListsApiHTTP } from './clients/ListsApi.http';
import { ContactsApiHTTP } from './clients/ContactsApi.http';
import { BrokersApiHTTP } from './clients/BrokersApi.http';

export const APIProvider = ({ children }: { children: ReactNode }) => {
	const { httpClient } = useContext(WithAuthenticatedContext);

	const value = useMemo(
		() => ({
			exportApi: new ExportApiHTTP(httpClient),
			listsApi: new ListsApiHTTP(httpClient),
			contactsApi: new ContactsApiHTTP(httpClient),
			brokersApi: new BrokersApiHTTP(httpClient),
		}),
		[httpClient],
	);

	return <APIContext.Provider value={value}>{children}</APIContext.Provider>;
};
