import cn from 'classnames';
import { components, ControlProps } from 'react-select';
import { CSSProperties } from 'react';
import { salesforce, bf_logo_new } from 'assets/index';
import { FaPlus, FaLock } from 'react-icons/fa6';
import { MdEdit } from 'react-icons/md';
import { MdOutlineEdit } from 'react-icons/md';
import { CrmIntegrationFieldDomainType } from 'common.model/src/types/services/integration/fieldMapping/FieldDomainType';

export enum FieldStateType {
	LOCKED = 'LOCKED',
	NEW = 'NEW',
	EDIT = 'EDIT',
}

const FieldStateIcon = ({ fieldStates }) => {
	const style: CSSProperties = { position: 'absolute', left: '-26px', pointerEvents: 'none' };

	if (fieldStates?.[FieldStateType.NEW]) {
		return <FaPlus size={18} color='#5ad4a1' style={style} />;
	} else if (fieldStates?.[FieldStateType.EDIT]) {
		return <MdOutlineEdit size={18} color='#f5dc4b' style={style} />;
	} else if (fieldStates?.[FieldStateType.LOCKED]) {
		return <FaLock size={18} color='#afbdcf' style={style} />;
	}
	return null;
};

const FieldTypeIcon = ({ fieldObjectDomainType }) => {
	const objectTypeIcons = {
		[CrmIntegrationFieldDomainType.BENEFIT_FLOW]: <img className={cn('img-xs', 'ms-2')} src={bf_logo_new} />,
		[CrmIntegrationFieldDomainType.SALESFORCE]: <img className={cn('img-xs', 'ms-2')} src={salesforce} />,
		[CrmIntegrationFieldDomainType.CUSTOM_CONSTANT]: <MdEdit size={19} className={cn('mx-2', 'text-secondary')} />,
		[CrmIntegrationFieldDomainType.SALESFORCE_CONSTANT]: <img className={cn('img-xs', 'ms-2')} src={salesforce} />,
	};

	return objectTypeIcons?.[fieldObjectDomainType] || null;
};

export const CustomSelectedField = ({ children, ...props }: ControlProps<any, false>) => {
	const fieldStates = props?.selectProps?.fieldStates;
	const showStateIcon = props?.selectProps?.showStateIcon;
	const fieldObjectDomainType = props?.selectProps?.fieldObjectDomainType;
	return (
		<components.Control {...props}>
			{showStateIcon && <FieldStateIcon fieldStates={fieldStates} />}
			<FieldTypeIcon fieldObjectDomainType={fieldObjectDomainType} />
			{children}
		</components.Control>
	);
};
