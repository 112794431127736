import { useContext } from 'react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { APIContext } from 'new-beginning/services/api/Api.context';
import { ClientData } from './types';
import { ListsApi, List } from 'new-beginning/services/api/clients/ListsApi';

export interface ListDTO {
	id: string;
	name: string;
	description: string;
	numberOfBrokerContacts: number;
	numberOfEmployerContacts: number;
	totalNumberOfContacts: number;
	createdAt: Date;
	updatedAt: Date;
}

export const useListsQuery = ({
	options,
	includePurchasedContactsList = false,
}: {
	/**
	 * Include purchased contacts list in the query result.
	 * @default false
	 */
	includePurchasedContactsList?: boolean;
	options?: UseQueryOptions<ClientData<ListsApi['list']>, unknown, ListDTO[]>;
} = {}) => {
	const { listsApi } = useContext(APIContext);
	const query = useQuery(['lists'], listsApi.list.bind(listsApi), {
		select: (data) => select({ data, includePurchasedContactsList }),
		...options,
	});

	return query;
};

const select = ({ data, includePurchasedContactsList }: { data: List[]; includePurchasedContactsList: boolean }): ListDTO[] => {
	const lists = data.map((data) => ({
		id: data.id,
		name: data.name,
		description: data.description,
		numberOfBrokerContacts: data.num_broker_contacts,
		numberOfEmployerContacts: data.num_employer_contacts,
		totalNumberOfContacts: data.num_employer_contacts + data.num_broker_contacts,
		createdAt: new Date(data.created_at),
		updatedAt: new Date(data.updated_at),
	}));
	if (!includePurchasedContactsList) lists.shift();

	return lists.sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB, undefined, { sensitivity: 'base' }));
};
