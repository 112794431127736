import { BrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { createContext } from 'react';

export type OnExportParameters =
	| {
			checkedMap: Record<string, boolean>;
			notCheckedMap: Record<string, boolean>;
			type: 'brokerContacts';
			filters: BrokerContactFilters;
	  }
	| {
			checkedMap: Record<string, boolean>;
			notCheckedMap: Record<string, boolean>;
			type: 'brokerContactsList';
			isSelecteAll: boolean;
			filters: BrokerContactFilters;
			listId: string;
	  }
	| {
			checkedMap: Record<string, boolean>;
			isSelecteAll: boolean;
			notCheckedMap: Record<string, boolean>;
			type: 'employerContactsList';
			filters: BrokerContactFilters;
			listId: string;
	  };

export interface ExportContextType {
	onExport: ({ checkedMap, notCheckedMap, filters, type }: OnExportParameters) => void;
	isExporting: boolean;
}

export const ExportContext = createContext<ExportContextType>({
	onExport: () => {},
	isExporting: false,
} as ExportContextType);
