import { useParams } from 'react-router-dom';
import { useCallback, useContext, useMemo } from 'react';
import { addNotification } from '@benefitflow/designsystem';

import { useContactsListStore } from '../../../../../ContactsList.store';
import { ContactsListContext } from '../../../../../ContactsList.context';
import { useListsQuery } from '../../../../../../../../queries/useListsQuery';
import { useDeleteContactFromListMutation } from '../../../../../../../../mutations/useDeleteContactFromListMutation';
import { getApiPayloadFromState } from '../../../../../../Search/BrokerContacts/components/List/components/Header/helpers/getApiPayloadFromState';

export const useDeleteSelected = () => {
	const { listId } = useParams();
	const { checkedMap, checkMode, tab } = useContactsListStore();
	const { filters } = useContext(ContactsListContext);
	const { data } = useListsQuery();
	const listName = useMemo(() => data?.find((list) => list.id == listId)?.name, [data, listId]);
	const { mutate, isLoading } = useDeleteContactFromListMutation({
		options: {
			onSuccess: ({ numberOfContactsThatWillBeDeleted }) => {
				addNotification({
					type: 'success',
					autoClose: false,
					title: `Deleted ${numberOfContactsThatWillBeDeleted} contacts from list.`,
					message: `Contacts will be removed from ${listName ? `"${listName}"` : 'list'} in a few minutes.`,
				});
			},
			onError: () => {
				addNotification({
					type: 'error',
					title: 'Error deleting contacts from list.',
					message: 'Please try again later.',
				});
			},
		},
	});
	const deleteSelected = useCallback(() => {
		const { checkedMap: contactsCheckedMap, notCheckedMap: contactsNotCheckedMap } = getApiPayloadFromState({ checked: checkedMap, checkMode });
		mutate({
			contactsCheckedMap,
			contactsNotCheckedMap,
			filters,
			isSelectAll: checkMode === 'select-all',
			itemType: tab === 'broker' ? 'BROKER_CONTACT' : 'EMPLOYER_CONTACT',
			listId,
		});
	}, [mutate, filters, checkedMap, checkMode, tab, listId]);

	return { deleteSelected, loading: isLoading };
};
