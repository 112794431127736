import { createContext } from 'react';

import { ExportApi } from './clients/';
import { ListsApi } from './clients/ListsApi';
import { ContactsApi } from './clients/ContactsApi';
import { BrokersApi } from './clients/BrokersApi';

export interface APIContextType {
	exportApi: ExportApi;
	listsApi: ListsApi;
	contactsApi: ContactsApi;
	brokersApi: BrokersApi;
}

export const APIContext = createContext<APIContextType>({} as APIContextType);
