import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { CrmObjectTypeEnum } from 'common.model/src/types/services/integration/fieldMapping/CrmObjectTypeEnum';
import { invert } from 'lodash-es';

interface IResultPayload {
  table: CrmObjectTypeEnum;
  result: any;
}

export const SALESFORCE_SETTINGS_OBJECT_TYPE_TO_TABS = {
  "BROKER": 0,
  "BROKER_OFFICE": 1,
  "BROKER_CONTACT": 2,
}

export const SALESFORCE_SETTINGS_TABS_TO_OBJECT_TYPE = invert(SALESFORCE_SETTINGS_OBJECT_TYPE_TO_TABS)

// The initial state of the SalesforceSettingsPage container
export const initialState: ContainerState = {
  selectedTab: 0,
  mappingTables: {
    BROKER_CONTACT: {
      fieldMapping: null,
      selectedObjectName: "Contact",
      objectNames: ["Contact"],
      salesforceFields: [],
      isSaleforceFieldNamesLoading: false,
      isSalesforceFieldNamesLoaded: false,
      isSavingFieldMappings: false,
      isGettingFieldMappings: false,
      isFieldMappingsLoaded: false,
    },
    BROKER: {
      fieldMapping: null,
      selectedObjectName: "Account",
      objectNames: ["Account"],
      salesforceFields: [],
      isSaleforceFieldNamesLoading: false,
      isSalesforceFieldNamesLoaded: false,
      isSavingFieldMappings: false,
      isGettingFieldMappings: false,
      isFieldMappingsLoaded: false
    },
    BROKER_OFFICE: {
      fieldMapping: null,
      selectedObjectName: "Account",
      objectNames: ["Account"],
      salesforceFields: [],
      isSaleforceFieldNamesLoading: false,
      isSalesforceFieldNamesLoaded: false,
      isSavingFieldMappings: false,
      isGettingFieldMappings: false,
      isFieldMappingsLoaded: false
    }
  },
  lastUpdatedTable: null,
};

const salesforceSettingsPageSlice = createSlice({
  name: 'salesforceSettingsPage',
  initialState,
  reducers: {
    updateFieldMapping(state, action: PayloadAction<IResultPayload>) {
      const cpy = JSON.parse(JSON.stringify(state.mappingTables));
      cpy[action.payload.table].fieldMapping = action.payload.result;
      state.mappingTables = cpy;
      state.lastUpdatedTable = action.payload.table;
    },
    getSalesforceFieldNames(state, action: PayloadAction<CrmObjectTypeEnum>) {
      const cpy = JSON.parse(JSON.stringify(state.mappingTables));
      cpy[action.payload].isSaleforceFieldNamesLoading = true;
      state.mappingTables = cpy;
      state.lastUpdatedTable = action.payload;
    },
    gotSalesforceFieldNames(state, action: PayloadAction<IResultPayload>) {
      const cpy = JSON.parse(JSON.stringify(state.mappingTables));
      cpy[action.payload.table].salesforceFields = action.payload.result;
      cpy[action.payload.table].isSaleforceFieldNamesLoading = false;
      cpy[action.payload.table].isSalesforceFieldNamesLoaded = true;
      state.mappingTables = cpy;
      state.lastUpdatedTable = action.payload.table;
    },
    saveFieldMappings(state, action: PayloadAction<CrmObjectTypeEnum>) {
      const cpy = JSON.parse(JSON.stringify(state.mappingTables));
      cpy[action.payload].isSavingFieldMappings = true;
      state.mappingTables = cpy;
      state.lastUpdatedTable = action.payload;
    },
    savedFieldMappings(state, action: PayloadAction<CrmObjectTypeEnum>) {
      const cpy = JSON.parse(JSON.stringify(state.mappingTables));
      cpy[action.payload].isSavingFieldMappings = false;
      state.mappingTables = cpy;
      state.lastUpdatedTable = action.payload;
    },
    getFieldMappings(state, action: PayloadAction<CrmObjectTypeEnum>) {
      const cpy = JSON.parse(JSON.stringify(state.mappingTables));
      cpy[action.payload].isGettingFieldMappings = true;
      state.mappingTables = cpy;
      state.lastUpdatedTable = action.payload;
    },
    gotFieldMappings(state, action: PayloadAction<IResultPayload>) {
      const cpy = JSON.parse(JSON.stringify(state.mappingTables));
      cpy[action.payload.table].isGettingFieldMappings = false;
      cpy[action.payload.table].isFieldMappingsLoaded = true;
      cpy[action.payload.table].fieldMapping = action.payload.result;
      state.mappingTables = cpy;
      state.lastUpdatedTable = action.payload.table;
    },
    updateSelectedTab(state, action: PayloadAction<number>) {
      state.selectedTab = action.payload;
    }
  }
});

export const { actions: salesforceSettingsPageActions, reducer, name: sliceKey } = salesforceSettingsPageSlice;