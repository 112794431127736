import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useContext } from 'react';

import { APIContext } from 'new-beginning/services/api/Api.context';
import { ClientData } from './types/ClientData';
import { ExportApi } from 'new-beginning/services/api/clients';

export const useExportDownloadLinkQuery = ({ exportId, options }: { exportId: string; options: UseQueryOptions<ClientData<ExportApi['getDownloadLink']>> }) => {
	const { exportApi } = useContext(APIContext);
	const query = useQuery<ClientData<ExportApi['getDownloadLink']>>(
		['exportDownloadLink', exportId],
		() => exportApi.getDownloadLink.bind(exportApi)(exportId),
		options,
	);

	return query;
};
