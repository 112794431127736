import { APIClient } from '../types';
import { QueryPath } from '../../../constants/path';
import { BrokerOffice, BrokersApi } from './BrokersApi';
import { BrokerContactFilters } from '../../../../app/containers/SearchPage/containers/BrokerSearchPage/types';

export class BrokersApiHTTP implements BrokersApi {
	constructor(readonly client: APIClient) {}

	async getContactsFilterOptions(filters: BrokerContactFilters) {
		const response = await this.client.post<any, any>(QueryPath.BrokerContactsFilters, filters);
		if (response.data.res.success) return response.data.res.data;
		throw new Error(response.data.res.data);
	}

	async brokerOffices({ search }: { search: string }): Promise<BrokerOffice[]> {
		const response = await this.client.post<any, any>('brokerContacts/contactLocationSearch', { brokerOfficeSearch: search, page: 0 });
		if (response.data.res.success) return response.data.res.data;
		throw new Error('Failed to fetch broker contact locations');
	}
}
