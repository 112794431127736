import { APIClient } from '../types';
import { QueryPath } from '../../../constants/path';
import { BrokerContactLocation, ContactsApi, ListContactsParams, PurchaseContactsInBulkParams, PurchaseContactsInBulkResponse } from './ContactsApi';

export class ContactsApiHTTP implements ContactsApi {
	constructor(readonly client: APIClient) {}

	async list({ filters, type, page, listId }: ListContactsParams) {
		const url = (() => {
			if (listId !== undefined) return QueryPath.ContactsFromList;
			return { broker: QueryPath.BrokerContacts, employer: QueryPath.EmployerContacts }[type];
		})();
		const params = (() => {
			if (listId !== undefined)
				return {
					listId,
					page,
					filters,
					itemType: type === 'broker' ? 'BROKER_CONTACT' : 'EMPLOYER_CONTACT',
				};
			return { ...filters, page };
		})();
		const response = await this.client.post<any, any>(url, params);
		if (response.data.res.success) return response.data.res.data;
		throw new Error('Failed to fetch contacts');
	}

	async purchaseContactsInBulk({
		checkedMap,
		notCheckedMap,
		filters,
		checkMode,
		type,
		listId,
	}: PurchaseContactsInBulkParams): Promise<PurchaseContactsInBulkResponse> {
		const url = (() => {
			if (listId) return '/lists/revealSelectedListItems';
			if (type === 'broker') return '/brokerContacts/bulkSelectAllGetContact';
			if (type === 'employer') throw new Error('Not implemented');
		})();
		const params = (() => {
			if (listId)
				return {
					listId,
					itemType: type === 'broker' ? 'BROKER_CONTACT' : 'EMPLOYER_CONTACT',
					isSelectAll: checkMode === 'select-all',
					contactsCheckedMap: checkedMap,
					contactsNotCheckedMap: notCheckedMap,
					filters,
				};
			return {
				checkedMap,
				notCheckedMap,
				filters,
			};
		})();

		const response = await this.client.post<any, any>(url, params);
		if (response.data.res.success) return response.data.res.data;
		throw new Error('Failed to purchase contacts');
	}

	async brokerContactLocations({ search }: { search: string }): Promise<BrokerContactLocation[]> {
		const response = await this.client.post<any, any>('brokerContacts/contactLocationSearch', { search, page: 0 });
		if (response.data.res.success) return response.data.res.data;
		throw new Error('Failed to fetch broker contact locations');
	}
}
