import { useCallback, useContext } from 'react';

import { useContactsListStore } from '../../../../../ContactsList.store';
import { ContactsListContext } from '../../../../../ContactsList.context';
import { ExportContext } from '../../../../../../../../context/ExportContext';
import { getApiPayloadFromState } from '../../../../../../Search/BrokerContacts/components/List/components/Header/helpers/getApiPayloadFromState';
import { useParams } from 'react-router-dom';

export const useExportSelected = () => {
	const { listId } = useParams();
	const { checkedMap, tab, checkMode } = useContactsListStore();
	const { filters } = useContext(ContactsListContext);
	const { isExporting, onExport } = useContext(ExportContext);
	const exportSelected = useCallback(() => {
		if (tab === 'broker') {
			onExport({
				...getApiPayloadFromState({ checked: checkedMap, checkMode }),
				filters,
				listId,
				type: 'brokerContactsList',
			});
		}
		if (tab === 'employer') {
			onExport({
				...getApiPayloadFromState({ checked: checkedMap, checkMode }),
				filters,
				listId,
				type: 'employerContactsList',
			});
		}
	}, [tab, onExport, filters, listId, checkedMap, checkMode]);

	return { exportSelected, isExporting };
};
